import styles from "../styles/common/navbar.module.css";
import {
  Button,
  Container,
  Form,
  Nav,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { signOut } from "supertokens-auth-react/recipe/thirdpartypasswordless";
import Calendar from "react-calendar";
import { ApiClient } from "../helpers/apiClient.js";
import { ApiClientType } from "../models/apiClientType.js";
import Modal from "react-bootstrap/Modal";
import dateFormat from "dateformat";
import "react-calendar/dist/Calendar.css";

export default function NavbarComponent(props) {
  let navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [activePfzDates, setActivePfzDates] = useState({});
  const [activeSpeciesDates, setActiveSpeciesDates] = useState({});
  const [showModal, setShowModal] = useState(false);

  let expand = "md";
  const { name } = props.user;
  const getInitials = (name) => {
    let initials = name.split(" ");
    if (initials.length > 1) {
      initials = initials.shift().charAt(0) + initials.pop().charAt(0);
    } else {
      initials = name.substring(0, 2);
    }
    return initials.toUpperCase();
  };

  useEffect(() => {
    if (options.length == 0) {
      props.value.landingCenterCoordinates?.features?.map((e) => {
        let obj = {
          landing_center_name: `${e.properties.LANDINGNAM}-${e.properties.STATENAME}`,
          landing_center_id: `${e.id}`,
          landing_center_coords: e.geometry.coordinates,
        };
        options.push(obj);
        setOptions(options);
      });
    }
  }, [props.value.landingCenterCoordinates]);

  useEffect(() => {
    getAvailableSpeciesDates();
    getAvailablePfzDates();
  }, []);
  const getAvailableSpeciesDates = async () => {
    let result = await ApiClient(
      ApiClientType.get,
      process.env.REACT_APP_SPECIES_AVAILABLE_URL,
      ``,
      {
        startDate: "2022-01-01",
        endDate: dateFormat(Date.now(), "yyyy-mm-dd"),
      }
    );
    setActiveSpeciesDates(result);
  };
  const getAvailablePfzDates = async () => {
    let result = await ApiClient(
      ApiClientType.get,
      process.env.REACT_APP_LANDING_CENTER_AVAILABLE_URL,
      ``,
      {
        startDate: "2022-01-01",
        endDate: dateFormat(Date.now(), "yyyy-mm-dd"),
      }
    );
    console.log(result);
    setActivePfzDates(result);
  };

  return (
    <Navbar
      key={expand}
      bg="light"
      expand={expand}
      id={styles.navbar}
      className="bg-white"
    >
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            {Object.keys(activePfzDates).length != 0 ? (
              <Calendar
                defaultValue={props.value.mapDate}
                onChange={(e) => {
                  props.value.setMapDate(dateFormat(e, "yyyy-mm-dd"));
                  setShowModal(false);
                }}
                tileClassName={(date) => {
                  if (date.view === "month") {
                    if (
                      activePfzDates[dateFormat(date.date, "yyyy-mm-dd")] !=
                      undefined
                    ) {
                      if (
                        activePfzDates[dateFormat(date.date, "yyyy-mm-dd")] ==
                        false
                      ) {
                        return "blocked";
                      }
                    }
                    if (
                      activeSpeciesDates[dateFormat(date.date, "yyyy-mm-dd")] !=
                      undefined
                    ) {
                      if (
                        activeSpeciesDates[
                          dateFormat(date.date, "yyyy-mm-dd")
                        ] == true
                      ) {
                        return "highlight";
                      }
                    }
                  }
                }}
                // tileDisabled={(date) => {
                //   if (date.view === "month") {
                //     if (
                //       activePfzDates[dateFormat(date.date, "yyyy-mm-dd")] !=
                //       undefined
                //     ) {
                //       if (
                //         activePfzDates[dateFormat(date.date, "yyyy-mm-dd")] ==
                //         true
                //       ) {
                //         return false;
                //       }
                //     }
                //     return true;
                //   }
                //   return false;
                // }}
              />
            ) : (
              "Loading..."
            )}
          </center>
        </Modal.Body>
      </Modal>
      <Container fluid>
        <Navbar.Brand href="#">Fishgram</Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              Filters
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-center flex-grow-1 pe-3">
              <Button variant="outline-success" id={styles.profile}>
                Profile
              </Button>
              <br />
              <Form className="d-md-inline-flex">
                <div
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    width: "150px",
                    height: "37px",
                    marginTop: "10px",
                    paddingTop: "5px",
                  }}
                  onClick={() => setShowModal(true)}
                >
                  {props.value.mapDate}
                </div>

                <br />
                <Dropdown>
                  <Dropdown.Toggle variant="" id={styles.dropdown}>
                    <Form.Control
                      className="mx-3 my-2 w-auto"
                      placeholder="Select Location"
                      onChange={(e) =>
                        setSearchValue(e.target.value.toLowerCase())
                      }
                      value={
                        searchValue != null
                          ? searchValue
                          : props.value.searchLandingCenter.landing_center_name
                      }
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{ maxHeight: "250px", overflow: "auto" }}
                  >
                    {options.length != 0 ? (
                      <>
                        {options.map((item, index) => {
                          if (searchValue != null && searchValue != "") {
                            if (
                              item.landing_center_name
                                .toLowerCase()
                                .startsWith(searchValue)
                            ) {
                              return (
                                <Dropdown.Item
                                  eventKey={item.landing_center_id}
                                  onClick={() => {
                                    props.value.setSearchLandingCenter(item);
                                    setSearchValue(null);
                                  }}
                                >
                                  {item.landing_center_name}
                                </Dropdown.Item>
                              );
                            }
                          } else {
                            return (
                              <Dropdown.Item
                                eventKey={item.landing_center_id}
                                onClick={() => {
                                  props.value.setSearchLandingCenter(item);
                                  setSearchValue(null);
                                }}
                              >
                                {item.landing_center_name}
                              </Dropdown.Item>
                            );
                          }
                        })}
                      </>
                    ) : (
                      <>Loading...</>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Form>
            </Nav>

            <Form.Check
              onChange={(e) => props.value.setIsWindy(!props.value.isWindy)}
              style={{
                marginTop: "14px",
                marginRight: "10px",
              }}
              type="switch"
              id="custom-switch"
              label="Windy View"
              checked={props.value.isWindy}
            />
            <Button
              style={{
                paddingTop: "2px",
                paddingBottom: "2px",
                height: "40px",
                marginTop: "7px",
              }}
              onClick={async () => {
                await signOut();
                window.location.href = "/home";
              }}
            >
              Log Out
            </Button>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
