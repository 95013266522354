import { ApiClientType } from "../models/apiClientType.js";
import axios from "axios";
export async function ApiClient(type, baseUrl, urlExtension, params) {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
  });
  axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
  axiosInstance.defaults.withCredentials = true;
  let headers = {};

  if (baseUrl == process.env.REACT_APP_CMS_BASE_URL) {
    headers["namespaceId"] = "cf";
  }
  let requestOptions = {
    method: type,
    headers: headers,
    data: params,
  };
  if (type == ApiClientType.get) {
    requestOptions["params"] = params;
  } else {
    requestOptions["data"] = params;
  }
  try {
    let response;
    if (
      baseUrl == process.env.REACT_APP_SPEICES_COORDINATES ||
      baseUrl == process.env.REACT_APP_LANDING_CENTER_COORDINATES
    ) {
      response = await axios(`${baseUrl}${urlExtension}`, requestOptions);
    } else {
      response = await axiosInstance(
        `${baseUrl}${urlExtension}`,
        requestOptions
      );
    }
    let status = await response.status;
    let res = await response.data;
    if (res) {
      return res;
    }
    return status;
  } catch (e) {
    // console.log(e);
  }
}
