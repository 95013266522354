import styles from "../styles/common/popup.module.css";
import dateFormat from "dateformat";

export default function LandingCenterPopup(props) {
  let landingCenter = props.value.landingCenter;
  if (props.value.landingCenter != undefined) {
    return (
      <div id={styles.popup}>
        <div id={styles.heading}>
          <p>Landing Center</p>
        </div>
        <div id={styles.body}>
          <h6 style={{ color: "#373D3F" }}>{landingCenter.name}</h6>
          <ul style={{ listStyle: "none", padding: 0, marginTop: "10px" }}>
            <li id={styles.li}>
              Type : <b>{landingCenter.Meta.Facilities.LandingCenterType}</b>{" "}
            </li>
            <li id={styles.li}>
              Fish Category :{" "}
              <b>
                {landingCenter.Meta.Facilities.FishCategory?.map((item) => {
                  return <>|{item}|</>;
                })}
              </b>{" "}
            </li>
            <li id={styles.li}>
              Facilities :{" "}
              <b>
                {landingCenter.Meta.Facilities.FacilitiesAvailable?.map(
                  (item) => {
                    return <>|{item}|</>;
                  }
                )}
              </b>{" "}
            </li>
            <li id={styles.li}>
              Boat :{" "}
              <b>
                {landingCenter.Meta.Resources.Sector?.map((item) => {
                  return <>|{item}|</>;
                })}
              </b>{" "}
            </li>
            <li id={styles.li}>
              Open Season :{" "}
              <b>
                {dateFormat(landingCenter.Meta.Dates.SeasonStart, "dd/mm/yyyy")}
                - {dateFormat(landingCenter.Meta.Dates.SeasonEnd, "dd/mm/yyyy")}
              </b>{" "}
            </li>
          </ul>
        </div>
        <div id={styles.footer}>
          <span
            onClick={() => {
              props.value.onEdit();
            }}
            style={{ color: "#0A82C6", cursor: "pointer", float: "right" }}
          >
            Edit/View Details
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div id={styles.popup}>
        <div id={styles.heading}>
          <p>Landing Center</p>
        </div>
        <div id={styles.body}>Loading....</div>
      </div>
    );
  }
}
